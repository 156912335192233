<script>
import ChangeLog from "@/components/ChangeLog.vue";

export default {
	name: "News",
	components: {
		ChangeLog,
	},
	data() {
		return {};
	},
};
</script>

<template>
	<div class="page">
		<b-container class="page__changelog">
			<b-row>
				<b-col>
					<h4>News</h4>
				</b-col>
			</b-row>
			<b-row>
				<!-- <b-col
                
                    offset-lg="2"
                    lg="8"
                >
                    
					<ChangeLog 
                        header="Change Log"
                    />
				</b-col> -->
				<b-col
                    lg="6"
                >
					<b-card title="Change Log" sub-title="Changes and additions to the card catalog">
						<b-card-text>
							<ChangeLog />
						</b-card-text>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
